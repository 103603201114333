import React, { useState, useEffect, useReducer, useRef, useContext } from "react";
import { isSameDay, parseISO, format } from "date-fns";
import clsx from "clsx";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  makeStyles,
  Badge
} from "@material-ui/core";
import {
  AccessTime,
  Done,
  DoneAll,
  ExpandMore,
  GetApp,
  Facebook,
  Instagram,
} from "@material-ui/icons";
import MarkdownWrapper from "../MarkdownWrapper";
import VcardPreview from "../VcardPreview";
import LocationPreview from "../LocationPreview";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";
import whatsBackground from "../../assets/wa-background.png";
import whatsBackgroundDark from "../../assets/wa-background-dark.png";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { SocketContext } from "../../context/Socket/SocketContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import AudioModal from "../AudioModal";
import { LinearProgress } from "@material-ui/core";

// IMPORTANTE: Para compressão de imagens (mesmo que não use em outro lugar):
import Compressor from "compressorjs";
import useSettings from "../../hooks/useSettings";

const useStyles = makeStyles((theme) => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
    minWidth: 200,
    minHeight: 200,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 15,
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease",
  },

  messageCenter: {
    height: "auto",
    display: "block",
    position: "relative",
    whiteSpace: "pre-wrap",
    backgroundColor: "#e1f3fb",
    fontSize: "14px",
    minWidth: 100,
    maxWidth: 600,
    color: "#272727",
    alignSelf: "center",
    borderRadius: 8,
    padding: 8,
    margin: 10,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messagesList: {
    backgroundImage:
      theme.palette.type === "light"
        ? `url(${whatsBackground})`
        : `url(${whatsBackgroundDark})`,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: 20,
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    position: "relative", // para podermos posicionar overlay
    minHeight: "100%",
  },

  circleLoading: {
    color: theme.palette.primary.main,
    position: "absolute",
    opacity: 0.7,
    top: 0,
    left: "50%",
    marginTop: 12,
    transition: "all 0.3s ease",
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 8,
    minWidth: 120,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },
    whiteSpace: "pre-wrap",
    backgroundColor: "#ffffff",
    color: "#303030",
    alignSelf: "flex-start",
    borderRadius: 12,
    padding: 10,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease",
  },

  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    borderRadius: 12,
    display: "flex",
    position: "relative",
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: 4,
    backgroundColor: "#6bcbef",
  },

  messageRight: {
    marginLeft: 20,
    marginTop: 8,
    minWidth: 120,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },
    whiteSpace: "pre-wrap",
    backgroundColor: "#dcf8c6",
    color: "#303030",
    alignSelf: "flex-end",
    borderRadius: 12,
    padding: 10,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease",
  },

  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#cfe9ba",
    borderRadius: 12,
    display: "flex",
    position: "relative",
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  quotedSideColorRight: {
    flex: "none",
    width: 4,
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: 0.9,
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  textContentItemEdited: {
    overflowWrap: "break-word",
    padding: "3px 120px 6px 6px",
  },

  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderRadius: 12,
    transition: "all 0.3s ease",
  },

  timestamp: {
    fontSize: 11,
    padding: 1,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: 120,
    backgroundColor: "#e1f3fb",
    margin: 10,
    borderRadius: 12,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },

  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: 0,
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: theme.palette.primary.main,
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },

  deletedMessage: {
    color: "#f55d65",
  },

  // *** ESTILOS PARA O OVERLAY DE "SOLTE O ARQUIVO AQUI" ***
  dragOverlay: {
    position: "fixed", // usa fixed para cobrir a tela toda
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.6)",
    zIndex: 9999,
    display: "flex",
    flexDirection: "column", // para alinhar título e subtítulo
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "none",
  },

  dragOverlayText: {
    color: "#fff",
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "10px",
    backgroundColor: "rgba(0,0,0,0.5)",
    padding: "15px 25px",
    borderRadius: 8,
  },

  dragOverlaySubText: {
    color: "#fff",
    fontSize: "16px",
    backgroundColor: "rgba(0,0,0,0.5)",
    padding: "10px 20px",
    borderRadius: 8,
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

// A lógica de compressão e upload do outro lugar
// adaptada para rodar neste MessagesList:

const MessagesList = ({ ticket, ticketId, isGroup }) => {
  const classes = useStyles();

  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastMessageRef = useRef();

  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef(ticketId);

  const socketManager = useContext(SocketContext);
  const { user } = useContext(AuthContext);

  // ARQUIVOS ARRASTADOS E BARRA DE PROGRESSO
  const [isDragging, setIsDragging] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  // ARMAZENA TEMPORARIAMENTE OS ARQUIVOS SOLTOS
  const [droppedMedias, setDroppedMedias] = useState([]);
  const [groqApiKey, setGroqApiKey] = useState("");
  const { getAll: getAllSettings } = useSettings();

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
    currentTicketId.current = ticketId;
  }, [ticketId]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        if (ticketId === undefined) return;
        try {
          const { data } = await api.get("/messages/" + ticketId, {
            params: { pageNumber },
          });

          if (currentTicketId.current === ticketId) {
            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
            setHasMore(data.hasMore);
            setLoading(false);
          }

          if (pageNumber === 1 && data.messages.length > 1) {
            scrollToBottom();
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchMessages();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, ticketId]);

  useEffect(() => {
    if (!user) return;
    const socket = socketManager.getSocket(user.companyId);
    const onConnect = () => {
      if (typeof ticket.id !== "undefined") {
        socket.emit("joinChatBox", `${ticket.id}`);
      }
    };
    socketManager.onConnect(onConnect);

    const onCompanyAppMessage = (data) => {
      if (
        data.action === "create" &&
        data.message.ticketId === currentTicketId.current
      ) {
        dispatch({ type: "ADD_MESSAGE", payload: data.message });
        scrollToBottom();
      }

      if (
        data.action === "update" &&
        data.message.ticketId === currentTicketId.current
      ) {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
    };

    socket.on(`company-${user.companyId}-appMessage`, onCompanyAppMessage);

    return () => {
      if (typeof ticket.id !== "undefined") {
        socket.emit("leaveChatBox", `${ticket.id}`);
      }
      socket.off("connect", onConnect);
      socket.off(`company-${user.companyId}-appMessage`, onCompanyAppMessage);
    };
  }, [ticketId, ticket, socketManager, user]);

  useEffect(() => {
    async function findData() {
      const settingList = await getAllSettings();
      if (Array.isArray(settingList)) {
        const groqApiKey = settingList.find(
          (d) => d.key === "groqApiKey"
        );
        if (groqApiKey) {
          setGroqApiKey(groqApiKey.value);
        }
      }
    }
    findData();
  }, [getAllSettings]);

  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const handleScroll = (e) => {
    if (!hasMore) return;
    if (loading) return;

    const { scrollTop } = e.currentTarget;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }
    if (scrollTop === 1) {
      loadMore();
    }
  };

  // --------------------------------------------
  // FUNÇÕES DE DRAG & DROP + UPLOAD
  // --------------------------------------------
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Verifica se o mouse saiu do container:
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsDragging(false);
    }
  };

  // Ao soltar, chamamos a lógica de upload (mesma do outro componente).
  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = Array.from(e.dataTransfer.files);
      setDroppedMedias(files);
      e.dataTransfer.clearData();

      // Subir imediatamente
      await handleUploadDroppedMedias(files);
    }
  };

  // Copiamos a "lógica de upload" (compressão, progress, etc.)
  const handleUploadDroppedMedias = async (files) => {
    if (!ticketId) return; // se não houver ticketId, não faz upload

    setUploadLoading(true);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append("fromMe", true);

    // Replicando a compressão de imagens e append do outro local
    for (let file of files) {
      if (file.type.split("/")[0] === "image") {
        // compress
        await new Promise((resolve) => {
          new Compressor(file, {
            quality: 0.7,
            async success(compressed) {
              formData.append("medias", compressed);
              formData.append("body", compressed.name);
              resolve();
            },
            error(err) {
              alert("Erro ao comprimir imagem");
              console.log(err.message);
              resolve(); // segue sem compress
            },
          });
        });
      } else {
        // arquivo não é imagem
        formData.append("medias", file);
        formData.append("body", file.name);
      }
    }

    // Aqui, simulamos o "delay" de 2s do outro local (opcional).
    setTimeout(async () => {
      try {
        await api.post(`/messages/${ticketId}`, formData, {
          onUploadProgress: (event) => {
            let progress = Math.round((event.loaded * 100) / event.total);
            setUploadProgress(progress);
          },
        });
        setUploadLoading(false);
        setDroppedMedias([]);
        setUploadProgress(0);
        console.log("Arquivos foram enviados com sucesso!");
      } catch (err) {
        toastError(err);
        setUploadLoading(false);
      }
    }, 1000);
  };
  // --------------------------------------------

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = () => {
    setAnchorEl(null);
  };

  const checkMessageMedia = (message) => {
    if (
      message.mediaType === "locationMessage" &&
      message.body.split("|").length >= 2
    ) {
      let locationParts = message.body.split("|");
      let imageLocation = locationParts[0];
      let linkLocation = locationParts[1];

      let descriptionLocation = null;
      if (locationParts.length > 2) {
        descriptionLocation = message.body.split("|")[2];
      }

      return (
        <LocationPreview
          image={imageLocation}
          link={linkLocation}
          description={descriptionLocation}
        />
      );
    }

    if (
      message.mediaType === "vcard" ||
      message.mediaType === "contactMessage"
    ) {
      let array = message.body.split("\n");
      let dados_nome_completo = array[3];
      let dados_numero_completo = array[4];
      let matchNome = dados_nome_completo.match(/(?<=:).*$/);
      let nome_contato = matchNome ? matchNome[0] : "";
      let matchNumero = dados_numero_completo.match(/(?:waid=)?(\d+)(?=:)/);
      let numero_completo = matchNumero ? matchNumero[1] : "";
      return <VcardPreview contact={nome_contato} numbers={numero_completo} />;
    }

    if (message.mediaType === "image") {
      return <ModalImageCors imageUrl={message.mediaUrl} />;
    }

    if (message.mediaType === "audio") {
      return <AudioModal url={message.mediaUrl} />;
    }

    if (message.mediaType === "video") {
      return (
        <video className={classes.messageMedia} src={message.mediaUrl} controls />
      );
    } else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              target="_blank"
              href={message.mediaUrl}
            >
              Download
            </Button>
          </div>
          <Divider />
        </>
      );
    }
  };

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span className={classes.dailyTimestamp} key={`timestamp-${message.id}`}>
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy hh:mm a")}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderNumberTicket = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageTicket = message.ticketId;
      let previousMessageTicket = messagesList[index - 1].ticketId;

      if (messageTicket !== previousMessageTicket) {
        return (
          <center>
            <div className={classes.ticketNunberClosed}>
              Conversa encerrada:{" "}
              {format(
                parseISO(messagesList[index - 1].createdAt),
                "dd/MM/yyyy HH:mm:ss"
              )}
            </div>

            <div className={classes.ticketNunberOpen}>
              Conversa iniciada:{" "}
              {format(parseISO(message.createdAt), "dd/MM/yyyy HH:mm:ss")}
            </div>
          </center>
        );
      }
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const renderQuotedMessage = (message) => {
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <div className={classes.quotedMsg}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              {message.quotedMsg?.contact?.name}
            </span>
          )}

          {message.quotedMsg.mediaType === "audio" && (
            <div className={classes.downloadMedia}>
              <audio controls>
                <source src={message.quotedMsg.mediaUrl} type="audio/ogg"></source>
              </audio>
            </div>
          )}
          {message.quotedMsg.mediaType === "video" && (
            <video
              className={classes.messageMedia}
              src={message.quotedMsg.mediaUrl}
              controls
            />
          )}
          {message.quotedMsg.mediaType === "application" && (
            <div className={classes.downloadMedia}>
              <Button
                startIcon={<GetApp />}
                color="primary"
                variant="outlined"
                target="_blank"
                href={message.quotedMsg.mediaUrl}
              >
                Download
              </Button>
            </div>
          )}

          {(message.quotedMsg.mediaType === "image" && (
            <ModalImageCors imageUrl={message.quotedMsg.mediaUrl} />
          )) ||
            message.quotedMsg?.body}
          {!message.quotedMsg.mediaType === "image" && message.quotedMsg?.body}
        </div>
      </div>
    );
  };

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {
        if (message.mediaType === "call_log") {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderNumberTicket(message, index)}
              {renderMessageDivider(message, index)}
              <div className={classes.messageCenter}>
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>
                {isGroup && (
                  <span className={classes.messageContactName}>
                    {message.contact?.name}
                  </span>
                )}
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 17"
                    width="20"
                    height="17"
                  >
                    <path
                      fill="#df3333"
                      d="M18.2 12.1c-1.5-1.8-5-2.7-8.2-2.7s-6.7 1-8.2 2.7c-.7.8-.3 2.3.2 2.8.2.2.3.3.5.3 1.4 0 3.6-.7 3.6-.7.5-.2.8-.5.8-1v-1.3c.7-1.2 5.4-1.2 6.4-.1l.1.1v1.3c0 .2.1.4.2.6.1.2.3.3.5.4 0 0 2.2.7 3.6.7.2 0 1.4-2 .5-3.1zM5.4 3.2l4.7 4.6 5.8-5.7-.9-.8L10.1 6 6.4 2.3h2.5V1H4.1v4.8h1.3V3.2z"
                    ></path>
                  </svg>{" "}
                  <span>
                    Chamada de voz/vídeo perdida às{" "}
                    {format(parseISO(message.createdAt), "HH:mm")}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        }

        if (
          message.mediaType === "closed_log" ||
          message.mediaType === "info_log"
        ) {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderNumberTicket(message, index)}
              {renderMessageDivider(message, index)}
              <div className={classes.messageCenter}>
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>
                {isGroup && (
                  <span className={classes.messageContactName}>
                    {message.contact?.name}
                  </span>
                )}
                <div>
                  <MarkdownWrapper>{message.body}</MarkdownWrapper>
                </div>
              </div>
            </React.Fragment>
          );
        }

        if (!message.fromMe) {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderNumberTicket(message, index)}
              {renderMessageDivider(message, index)}
              <div
                className={classes.messageLeft}
                title={message.queueId && message.queue?.name}
              >
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>

                {isGroup && (
                  <span className={classes.messageContactName}>
                    {message.contact?.name}
                  </span>
                )}

                {message.isDeleted && (
                  <div>
                    <span className={classes.deletedMessage}>
                      🚫 Esta mensagem foi apagada pelo Contato. &nbsp;
                    </span>
                  </div>
                )}

                {(message.mediaUrl ||
                  message.mediaType === "contactMessage" ||
                  message.mediaType === "locationMessage" ||
                  message.mediaType === "contactsArrayMessage") &&
                  checkMessageMedia(message)}

                <div className={classes.textContentItem}>
                  {message.quotedMsg && renderQuotedMessage(message)}

                  {message.mediaType !== "reactionMessage" &&
                    message.mediaType !== "locationMessage" &&
                    !(
                      message.mediaType === "contactMessage" ||
                      message.mediaType === "contactsArrayMessage"
                    ) && <MarkdownWrapper>{message.body}</MarkdownWrapper>}

                  {message.quotedMsg && message.mediaType === "reactionMessage" && (
                    <>
                      <Badge
                        className={classes.badge}
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <span
                            style={{
                              fontSize: "1.6em",
                              marginTop: "-7px",
                              marginLeft: "259px",
                            }}
                          >
                            {message.body}
                          </span>
                        }
                      ></Badge>
                      <span style={{ marginLeft: "0px" }}>
                        <MarkdownWrapper>
                          {"_*" + message.contact.name + "*_ reagiu..."}
                        </MarkdownWrapper>
                      </span>
                    </>
                  )}

                  <span className={classes.timestamp}>
                    {message.isEdited && <span>Editada </span>}
                    {format(parseISO(message.createdAt), "HH:mm")}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              <div
                className={classes.messageRight}
                title={message.queueId && message.queue?.name}
              >
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>

                {(message.mediaUrl ||
                  message.mediaType === "contactMessage" ||
                  message.mediaType === "locationMessage" ||
                  message.mediaType === "contactsArrayMessage") &&
                  checkMessageMedia(message)}
                <div
                  className={clsx(classes.textContentItem, {
                    [classes.textContentItemDeleted]: message.isDeleted,
                    [classes.textContentItemEdited]: message.isEdited,
                  })}
                >
                  {message.isDeleted && `🚫`}

                  {message.quotedMsg && renderQuotedMessage(message)}

                  {message.mediaType !== "reactionMessage" &&
                    message.mediaType !== "locationMessage" &&
                    !(
                      message.mediaType === "contactMessage" ||
                      message.mediaType === "contactsArrayMessage"
                    ) && <MarkdownWrapper>{message.body}</MarkdownWrapper>}

                  {message.quotedMsg && message.mediaType === "reactionMessage" && (
                    <>
                      <Badge
                        className={classes.badge}
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <span
                            style={{ fontSize: "4em", marginTop: "42px", marginLeft: "0px" }}
                          >
                            {message.body.split("*")[2]}
                          </span>
                        }
                      ></Badge>
                      <span style={{ marginLeft: "-135px" }}>
                        <MarkdownWrapper>
                          {message.body.split(":")[0] + "* reagiu...."}
                        </MarkdownWrapper>
                      </span>
                    </>
                  )}

                  <span className={classes.timestamp}>
                    {message.isEdited && <span>Editada </span>}
                    {format(parseISO(message.createdAt), "HH:mm")}
                    {renderMessageAck(message)}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        }
      });
      return viewMessagesList;
    } else {
      return <div>¡Saudações ao novo Lead!</div>;
    }
  };

  return (
    <div className={classes.messagesListWrapper}>
      <MessageOptionsMenu
        message={selectedMessage}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
        groq={groqApiKey}
      />

      <div
        id="messagesList"
        className={classes.messagesList}
        onScroll={handleScroll}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {/* Exibe overlay quando está arrastando um arquivo */}
        {isDragging && (
          <div className={classes.dragOverlay}>
            <div className={classes.dragOverlayText}>
              Você pode soltar o arquivo aqui
            </div>
          </div>
        )}

        {messagesList.length > 0 ? renderMessages() : []}
        {/* Referência final (para rolagem) */}
      </div>

      {/* Exemplo simples de barra de progresso / spinner se estiver subindo arquivo */}
      {uploadLoading && (
      <div style={{ textAlign: "center", padding: "8px 0" }}>
        <LinearProgress
          variant={uploadProgress > 0 ? "determinate" : "indeterminate"}
          value={uploadProgress}
          style={{ height: "6px", borderRadius: "4px", marginBottom: "4px" }}
        />
        <div style={{ fontSize: "0.9rem" }}>{uploadProgress}%</div>
      </div>
    )}

      {ticket?.channel !== "whatsapp" && (
        <div
          style={{
            width: "100%",
            display: "flex",
            padding: "10px",
            alignItems: "center",
            backgroundColor: "#E1F3FB",
          }}
        >
          {ticket?.channel === "facebook" ? (
            <Facebook small />
          ) : (
            <Instagram small />
          )}
          <span>
            Tem 24 horas para responder após receber uma mensagem, seguindo as
            políticas do Meta.
          </span>
        </div>
      )}

      {loading && (
        <div>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}
    </div>
  );
};

export default MessagesList;
