import React, { useContext } from "react";
import { Route as RouterRoute, Redirect } from "react-router-dom";
import { AuthContext } from "../context/Auth/AuthContext";
import moment from "moment";

const Route = ({ component: Component, isPrivate = false, ...rest }) => {
  const { isAuth, user } = useContext(AuthContext);

  const isSusbcriptionActive = () => {
    if (!user?.company?.dueDate) return true;
    if (user.company.id === 1) return true;
    const dueDate = moment(user.company.dueDate);
    const today = moment();
    return dueDate.isAfter(today);
  };

  // Redireciona para tickets se tentar acessar páginas públicas estando autenticado
  if (!isPrivate && isAuth) {
    return <Redirect to="/tickets" />;
  }

  // Redireciona para login se tentar acessar páginas privadas sem estar autenticado
  if (isPrivate && !isAuth) {
    return <Redirect to="/login" />;
  }

  // Verifica se a assinatura está vencida e redireciona para /financeiro
  if (isPrivate && isAuth && !isSusbcriptionActive()) {
    // Lista de rotas permitidas mesmo com assinatura vencida
    const allowedRoutes = ["/financeiro", "/login", "/forgetpsw"];
    
    // Se a rota atual não estiver na lista de permitidas, redireciona para /financeiro
    if (!allowedRoutes.includes(rest.path)) {
      return <Redirect to="/financeiro" />;
    }
  }

  return <RouterRoute {...rest} component={Component} />;
};

export default Route;