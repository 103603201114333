import React, { useContext, useState } from "react";
import {
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tabs,
  Tab,
  Tooltip,
  Avatar
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useDate } from "../../hooks/useDate";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ChatIcon from '@material-ui/icons/Chat';
import GroupIcon from '@material-ui/icons/Group';
import ConfirmationModal from "../../components/ConfirmationModal";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    height: "calc(100% - 58px)",
    overflow: "hidden",
    borderRadius: 0,
    backgroundColor: theme.palette.background.default,
  },
  chatList: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  secondaryTxt: {
    color: theme.palette.text.primary,
  },
  getItemStyles: {
    backgroundColor: theme.palette.background.default,
  },
  listItem: {
    cursor: "pointer",
  },
  tabPanel: {
    flex: 1,
  },
}));

export default function ChatList({
  chats,
  chatsPersonals,
  handleSelectChat,
  handleDeleteChat,
  handleEditChat,
  pageInfo,
  loading,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { datetimeToClient } = useDate();
  const [confirmationModal, setConfirmModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const { id } = useParams();

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const goToMessages = async (chat) => {
    if (unreadMessages(chat) > 0) {
      try {
        await api.post(`/chats/${chat.id}/read`, { userId: user.id });
      } catch (err) { }
    }

    if (id !== chat.uuid) {
      history.push(`/chats/${chat.uuid}`);
      handleSelectChat(chat);
    }
  };

  const handleDelete = () => {
    handleDeleteChat(selectedChat);
  };

  const unreadMessages = (chat) => {
    const currentUser = chat.users.find((u) => u.userId === user.id);
    return currentUser.unreads;
  };

  const getPrimaryText = (chat) => {
    const mainText = user.name === chat.title ? chat.titlePersonals : chat.title;
    const unreads = unreadMessages(chat);
    return (
      <>
        <div>

          <div style={{ marginLeft: '10px' }}>
            {mainText}
          </div>
          {unreads > 0 && (
            <Chip
              size="small"
              style={{ marginLeft: 5 }}
              label={unreads}
              color="secondary"
            />
          )}
        </div>
      </>
    );
  };

  const getSecondaryText = (chat) => {
    const secondaryText = chat.lastMessage !== "" ?
      `${datetimeToClient(chat.updatedAt)}: ${chat.lastMessage}` :
      "";

    return (
      <span className={classes.secondaryTxt}>{secondaryText}</span>
    );
  };

  const getItemStyle = (chat) => {
    return {
      borderLeft: chat.uuid === id ? "6px solid #002d6e" : null,
      backgroundColor: chat.uuid === id ? classes.getItemStyles : null,
    };
  };

  const handleRenderProfilePicture = (chat) => {

    const filterPictures = chat.users.filter((i) => i.userId !== user?.id)[0]?.user?.profilePicture

    return filterPictures
  }

  return (
    <>
      <ConfirmationModal
        title={"Excluir Conversa"}
        open={confirmationModal}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={handleDelete}
      >
        Esta ação não pode ser revertida, confirmar?
      </ConfirmationModal>
      <div className={classes.mainContainer}>
        <Tabs value={currentTab} onChange={handleChangeTab} style={{ display: 'flex' }}>

          <Tab
            icon={
              <Tooltip title="Conversas Pessoais">
                <ChatIcon color="primary" />
              </Tooltip>
            }
            style={{ flexGrow: 1 }}
          />

          <Tab
            icon={
              <Tooltip title="Conversas em Grupo">
                <GroupIcon color="primary" />
              </Tooltip>
            }
            style={{ flexGrow: 1 }}
          />


        </Tabs>
        {currentTab === 0 && (
          <div className={classes.chatList}>
            <List>
              {Array.isArray(chatsPersonals) && chatsPersonals.length > 0 &&
                chatsPersonals.map((chat, key) => (
                  <ListItem
                    onClick={() => goToMessages(chat)}
                    key={key}
                    className={classes.listItem}
                    style={getItemStyle(chat)}
                    button
                  >
                    <Avatar
                      src={handleRenderProfilePicture(chat)}
                    />

                    <ListItemText
                      primary={getPrimaryText(chat)}
                      secondary={getSecondaryText(chat)}
                    />
                  </ListItem>
                ))}
            </List>
          </div>
        )}
        {currentTab === 1 && (
          <div className={classes.chatList}>
            <List>
              {Array.isArray(chats) && chats.length > 0 &&
                chats.map((chat, key) => (
                  <ListItem
                    onClick={() => goToMessages(chat)}
                    key={key}
                    className={classes.listItem}
                    style={getItemStyle(chat)}
                    button
                  >
                    <ListItemText
                      primary={getPrimaryText(chat)}
                      secondary={getSecondaryText(chat)}
                    />
                    {chat.ownerId === user.id && (
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => {
                            goToMessages(chat).then(() => {
                              handleEditChat(chat);
                            });
                          }}
                          edge="end"
                          aria-label="edit"
                          size="small"
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setSelectedChat(chat);
                            setConfirmModalOpen(true);
                          }}
                          edge="end"
                          aria-label="delete"
                          size="small"
                          color="primary"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
            </List>
          </div>
        )}
      </div>
    </>
  );
}
