import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const selectFieldStyles = {
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "#909090",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#000000",
    borderWidth: "thin",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#9a00ed",
    borderWidth: "thin",
  },
};

const TransferSchema = Yup.object().shape({
  tagId: Yup.string().required("Selecione uma Tag!"),
});

const FlowBuilderAddTagModal = ({ open, onSave, onUpdate, data, close }) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const [tags, setTags] = useState([]);
  const [tagId, setTagId] = useState('');
  const [labels, setLabels] = useState({
    title: "Transferir Atendimento",
    btn: "Transferir",
  });

  useEffect(() => {
    if (open) {
      fetchTags();
      if (open === "edit") {
        setLabels({
          title: "Editar Tag",
          btn: "Salvar",
        });
        setTagId(data.data.tag);
      } else if (open === "create") {
        setLabels({
          title: "Adicionar Tag ao fluxo",
          btn: "Adicionar",
        });
        setTagId('');
      }
    }
  }, [open, data]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchTags = async () => {
    try {
      const { data } = await api.get("/tags");
      if (isMounted.current) {
        setTags(data.tags);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    close(null);
  };

  const handleSaveNode = async (values) => {
    try {
      if (values.tagId === '') return;
      if (open === "edit") {
        onUpdate({
          ...data,
          data: { tag: values.tagId },
        });
      } else if (open === "create") {
        onSave({
          tag: values.tagId,
        });
      }
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={Boolean(open)} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title">{labels.title}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              tagId: tagId,
            }}
            validationSchema={TransferSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSaveNode(values);
              setSubmitting(false);
            }}
            enableReinitialize
          >
            {({ errors, touched, isSubmitting, values, handleChange }) => (
              <Form>
                <Stack spacing={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Tag</InputLabel>
                    <Field
                      name="tagId"
                      as={Select}
                      label="Tag"
                      value={values.tagId}
                      onChange={(e) => handleChange(e)}
                      style={selectFieldStyles}
                      error={touched.tagId && !!errors.tagId}
                    >
                      {tags.map((tag) => (
                        <MenuItem key={tag.id} value={tag.id}>
                          {tag.name}
                        </MenuItem>
                      ))}
                    </Field>
                    {errors.tagId && touched.tagId ? (
                      <div>{errors.tagId}</div>
                    ) : null}
                  </FormControl>

                  <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                      {i18n.t("contactModal.buttons.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={classes.btnWrapper}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={24} className={classes.buttonProgress} /> : labels.btn}
                    </Button>
                  </DialogActions>
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FlowBuilderAddTagModal;
