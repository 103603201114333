import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { i18n } from "../../translate/i18n";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack
} from "@mui/material";
import { AddCircle, Delete } from "@mui/icons-material";
import FlowBuilderTestRequest from "../FlowBuilderTestRequest";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },

    btnWrapper: {
        position: "relative"
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));

const selectFieldStyles = {
    ".MuiOutlinedInput-notchedOutline": {
        borderColor: "#909090"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000000",
        borderWidth: "thin"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#9a00ed",
        borderWidth: "thin"
    }
};

const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Muito curto!")
        .max(50, "Muito longo!")
        .required("Digite um nome!"),
    text: Yup.string()
        .min(2, "Muito curto!")
        .max(50, "Muito longo!")
        .required("Digite uma mensagem!")
});

const FlowBuilderHttpRequestModal = ({ open, onSave, onUpdate, data, close }) => {
    const classes = useStyles();
    const isMounted = useRef(true);

    const [activeModal, setActiveModal] = useState(false);
    const [textDig, setTextDig] = useState("");
    const [textDig2, setTextDig2] = useState("");
    const [arrayOption, setArrayOption] = useState([]);
    const [arrayOption2, setArrayOption2] = useState([]);
    const [method, setMethod] = useState('');
    const [modalHttpRequestOpen, setModalHttRequestOpen] = useState('');
    const [labels, setLabels] = useState({
        title: "Adicionar Http Request ao fluxo",
        btn: "Adicionar"
    });

    useEffect(() => {
        if (open === "edit") {
            setLabels({
                title: "Editar Http Request",
                btn: "Salvar"
            });
            console.log(data)
            setMethod(data.data.method)
            setTextDig(data.data.body);
            setTextDig2(data.data.url);
            setArrayOption(data.data.headers);
            setArrayOption2(data.data.params);
            setActiveModal(true);
        } else if (open === "create") {
            setLabels({
                title: "Adicionar menu ao fluxo",
                btn: "Adicionar"
            });
            setTextDig("");
            setArrayOption([]);
            setArrayOption2([]);
            setMethod('')
            setTextDig2("");
            setActiveModal(true);
        } else {
            setActiveModal(false);
        }
    }, [open]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleClose = () => {
        close(null);
        setActiveModal(false);
    };

    const handleSaveContact = async () => {
        if (open === "edit") {
            handleClose();
            onUpdate({
                ...data,
                data: { url: textDig2, method: method, body: textDig, headers: arrayOption, params: arrayOption2 }
            });
        } else if (open === "create") {
            handleClose();
            console.log('OnSave:', "url:", textDig2, "method:", method, "body:", textDig, "headers:", arrayOption, "params:", arrayOption2)
            onSave({
                url: textDig2, method: method, body: textDig, headers: arrayOption, params: arrayOption2
            });
        }
    };

    const removeOption = number => {
        setArrayOption(old => old.filter(item => item.number !== number));
    };
    const removeOption2 = number => {
        setArrayOption2(old => old.filter(item => item.number !== number));
    };

    const updateOption = (index, field, value) => {
        setArrayOption(old => {
            let newArr = [...old];
            newArr[index][field] = value;
            return newArr;
        });
    };

    const updateOption2 = (index, field, value) => {
        setArrayOption2(old => {
            let newArr = [...old];
            newArr[index][field] = value;
            return newArr;
        });
    };

    const handleOpenModalHttpRequest = () => {
        setModalHttRequestOpen(true)
    }

    return (
        <div className={classes.root}>
            <FlowBuilderTestRequest
                open={modalHttpRequestOpen}
                data={data}
                close={setModalHttRequestOpen}
            />
            <Dialog
                open={activeModal}
                onClose={handleClose}
                fullWidth="md"
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">{labels.title}</DialogTitle>
                <Stack>
                    <Typography style={{ marginLeft: '15px' }}>URL:</Typography>
                    <Stack dividers style={{ gap: "8px", padding: "16px" }} >
                        <TextField
                            label={"URL - (LINK API)"}
                            minRows={1}
                            name="url"
                            multiline
                            variant="outlined"
                            value={textDig2}
                            onChange={e => setTextDig2(e.target.value)}
                            className={classes.textField}
                            style={{ width: "100%" }}
                        />
                    </Stack>
                    <Typography style={{ marginLeft: '15px' }}>Método:</Typography>
                    <Stack dividers style={{ gap: "8px", padding: "16px" }} >

                        <FormControl fullWidth >
                            <InputLabel id="method-request">Método</InputLabel>
                            <Select
                                id="method-request"
                                labelId="method-request"
                                name="method"
                                label={"Requisição"}
                                value={method}
                                onChange={(e) => {
                                    setMethod(e.target.value);
                                }}
                            >
                                <MenuItem key="get" value="get">GET</MenuItem>
                                <MenuItem key="post" value="post">POST</MenuItem>
                                <MenuItem key="put" value="put">PUT</MenuItem>
                                <MenuItem key="delete" value="delete">DELETE</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack dividers style={{ gap: "8px", padding: "16px" }}>
                        <TextField
                            label={"Body - (Conteudo em Json)"}
                            rows={4}
                            name="body"
                            multiline
                            variant="outlined"
                            value={textDig}
                            onChange={e => setTextDig(e.target.value)}
                            className={classes.textField}
                            style={{ width: "100%" }}
                        />
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography>Headers</Typography>
                            <Button
                                onClick={() =>
                                    setArrayOption(old => [
                                        ...old,
                                        { number: old.length + 1, value: "" }
                                    ])
                                }
                                color="primary"
                                variant="contained"
                            >
                                <AddCircle />
                            </Button>
                        </Stack>
                        {arrayOption.map((item, index) => (
                            <Stack key={item.number} width="100%" alignItems="center">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <TextField
                                        placeholder={`Chave`}
                                        variant="outlined"
                                        value={item.key}
                                        style={{ width: "100%" }}
                                        onChange={e => updateOption(index, 'key', e.target.value)}
                                    />
                                    <TextField
                                        placeholder={`Conteúdo`}
                                        variant="outlined"
                                        value={item.value}
                                        style={{ width: "100%" }}
                                        onChange={e => updateOption(index, 'value', e.target.value)}
                                    />
                                    <IconButton onClick={() => removeOption(item.number)}>
                                        <Delete />
                                    </IconButton>
                                </div>
                            </Stack>
                        ))}
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography>Parâmetros</Typography>
                            <Button
                                onClick={() =>
                                    setArrayOption2(old => [
                                        ...old,
                                        { number: old.length + 1, value: "" }
                                    ])
                                }
                                color="primary"
                                variant="contained"
                            >
                                <AddCircle />
                            </Button>
                        </Stack>
                        {arrayOption2.map((item, index) => (
                            <Stack key={item.number} width="100%" alignItems="center">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <TextField
                                        placeholder={`Chave`}
                                        variant="outlined"
                                        value={item.key}
                                        style={{ width: "100%" }}
                                        onChange={e => updateOption2(index, 'key', e.target.value)}
                                    />
                                    <TextField
                                        placeholder={`Conteúdo`}
                                        variant="outlined"
                                        value={item.value}
                                        style={{ width: "100%" }}
                                        onChange={e => updateOption2(index, 'value', e.target.value)}
                                    />
                                    <IconButton onClick={() => removeOption2(item.number)}>
                                        <Delete />
                                    </IconButton>
                                </div>
                            </Stack>
                        ))}
                    </Stack>
                    <DialogActions>
                        <Button onClick={handleOpenModalHttpRequest} color="secondary" variant="outlined">
                            {i18n.t("Testar")}
                        </Button>
                        <Button onClick={handleClose} color="secondary" variant="outlined">
                            {i18n.t("contactModal.buttons.cancel")}
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={classes.btnWrapper}
                            onClick={() => handleSaveContact()}
                        >
                            {`${labels.btn}`}
                        </Button>
                    </DialogActions>
                </Stack>
            </Dialog>
        </div>
    );
};

export default FlowBuilderHttpRequestModal;
