import React, { useState, useEffect, useReducer, useContext } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import ConfirmationModal from "../../components/ConfirmationModal/";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import NewTicketModal from "../../components/NewTicketModal";
import {
  DevicesFold
} from "@mui/icons-material";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FlowBuilderModal from "../../components/FlowBuilderModal";
import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  InputAdornment,
  TextField,
  Chip,
  Divider,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import { SocketContext } from "../../context/Socket/SocketContext";
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    return action.payload;
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex(c => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex(c => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    maxWidth: '100%',
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 600,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
      fontSize: '1.8rem',
    },
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    gap: theme.spacing(2),
  },
  searchField: {
    flex: 1,
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
    },
  },
  addButton: {
    height: '56px',
    borderRadius: '8px',
    padding: '0 24px',
    fontWeight: 500,
    fontSize: '1rem',
    textTransform: 'none',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      boxShadow: '0 6px 10px rgba(0, 0, 0, 0.15)',
    },
  },
  tableCard: {
    borderRadius: '12px',
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
    overflow: 'hidden',
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: theme.spacing(3),
  },
  tableHeaderTitle: {
    fontSize: '1.25rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
  tableHeaderCell: {
    backgroundColor: 'rgba(33, 150, 243, 0.08)',
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '0.875rem',
    padding: '16px 24px',
    borderBottom: '2px solid rgba(33, 150, 243, 0.2)',
  },
  tableCell: {
    padding: '16px 24px',
    fontSize: '0.95rem',
    borderBottom: '1px solid rgba(224, 224, 224, 0.5)',
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: 'rgba(33, 150, 243, 0.04)',
    },
  },
  flowNameCell: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1.5),
      color: theme.palette.primary.main,
      fontSize: '1.5rem',
    },
  },
  statusChip: {
    borderRadius: '20px',
    fontWeight: 500,
    padding: '6px 12px',
    fontSize: '0.8rem',
  },
  activeChip: {
    backgroundColor: 'rgba(76, 175, 80, 0.1)',
    color: '#4CAF50',
    border: '1px solid #4CAF50',
  },
  inactiveChip: {
    backgroundColor: 'rgba(158, 158, 158, 0.1)',
    color: '#9E9E9E',
    border: '1px solid #9E9E9E',
  },
  actionButton: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(33, 150, 243, 0.08)',
    },
  },
  actionButtonDelete: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: 'rgba(244, 67, 54, 0.08)',
    },
  },
  actionIcon: {
    fontSize: '1.2rem',
  },
  paginationContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  noDataContainer: {
    padding: theme.spacing(6),
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  noDataIcon: {
    fontSize: '3rem',
    color: 'rgba(0, 0, 0, 0.2)',
    marginBottom: theme.spacing(2),
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(6),
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: '10px 16px',
    '& svg': {
      fontSize: '1.2rem',
    },
  },
  menuItemText: {
    fontSize: '0.9rem',
  },
  menuItemDelete: {
    color: theme.palette.error.main,
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));

const FlowBuilder = () => {
  const classes = useStyles();
  const history = useHistory();

  const { user } = useContext(AuthContext);
  const socketManager = useContext(SocketContext);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [webhooks, setWebhooks] = useState([]);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [selectedWebhookName, setSelectedWebhookName] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [contactTicket, setContactTicket] = useState({});
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmDuplicateOpen, setConfirmDuplicateOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredWebhooks, setFilteredWebhooks] = useState([]);
  const [menuWebhook, setMenuWebhook] = useState(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    if (searchParam) {
      const filtered = webhooks.filter(webhook =>
        webhook.name.toLowerCase().includes(searchParam.toLowerCase())
      );
      setFilteredWebhooks(filtered);
    } else {
      setFilteredWebhooks(webhooks);
    }
  }, [searchParam, webhooks]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/flowbuilder");
          setWebhooks(data.flows);
          dispatch({ type: "LOAD_CONTACTS", payload: data.flows });
          setTotalPages(Math.ceil(data.count / 20));
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
          setLoading(false);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, reloadData]);

  useEffect(() => {
    const socket = socketManager.getSocket(user.companyId);

    socket.on(`company-${user.companyId}-contact`, data => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      // Cleanup function
    };
  }, [socketManager, user.companyId]);

  const handleSearch = event => {
    setSearchParam(event.target.value);
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setSelectedWebhookName(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setSelectedWebhookName(null);
    setContactModalOpen(false);
  };

  const handleCloseOrOpenTicket = ticket => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const handleEditContact = (webhook) => {
    setSelectedContactId(webhook.id);
    setSelectedWebhookName(webhook.name);
    setContactModalOpen(true);
  };

  const handleDeleteWebhook = async webhookId => {
    try {
      await api.delete(`/flowbuilder/${webhookId}`);
      setDeletingContact(null);
      setReloadData(old => !old);
      toast.success("Fluxo excluído com sucesso");
    } catch (err) {
      toastError(err);
    }
  };

  const handleDuplicateFlow = async flowId => {
    try {
      await api.post(`/flowbuilder/duplicate`, { flowId: flowId });
      setDeletingContact(null);
      setReloadData(old => !old);
      toast.success("Fluxo duplicado com sucesso");
    } catch (err) {
      toastError(err);
    }
  };

  const handleMenuOpen = (event, webhook) => {
    setAnchorEl(event.currentTarget);
    setMenuWebhook(webhook);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuWebhook(null);
  };

  const handleEditFlow = (webhook) => {
    history.push(`/flowbuilder/${webhook.id}`);
  };

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const handleRowClick = (webhookId) => {
    history.push(`/flowbuilder/${webhookId}`);
  };

  const itemsPerPage = 10;
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedWebhooks = filteredWebhooks.slice(startIndex, endIndex);

  return (
    <Box className={classes.root}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        initialContact={contactTicket}
        onClose={handleCloseOrOpenTicket}
      />

      <FlowBuilderModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        flowId={selectedContactId}
        nameWebhook={selectedWebhookName}
        onSave={() => setReloadData(old => !old)}
      />

      <ConfirmationModal
        title={
          deletingContact
            ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${deletingContact.name}?`
            : `${i18n.t("contacts.confirmationModal.importTitlte")}`
        }
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={() => deletingContact && handleDeleteWebhook(deletingContact.id)}
      >
        {deletingContact
          ? `Tem certeza que deseja deletar este fluxo? Todas as integrações relacionadas serão perdidas.`
          : `${i18n.t("contacts.confirmationModal.importMessage")}`}
      </ConfirmationModal>

      <ConfirmationModal
        title={
          deletingContact
            ? `Deseja duplicar o fluxo ${deletingContact.name}?`
            : `${i18n.t("contacts.confirmationModal.importTitlte")}`
        }
        open={confirmDuplicateOpen}
        onClose={() => setConfirmDuplicateOpen(false)}
        onConfirm={() => deletingContact && handleDuplicateFlow(deletingContact.id)}
      >
        {deletingContact
          ? `Tem certeza que deseja duplicar este fluxo?`
          : `${i18n.t("contacts.confirmationModal.importMessage")}`}
      </ConfirmationModal>

      <Box className={classes.header}>
        <Typography variant="h6" className={classes.title}>
          <DevicesFold /> Gerenciador de Fluxos
        </Typography>
      </Box>

      <Box className={classes.searchContainer}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Buscar fluxos por nome..."
          value={searchParam}
          onChange={handleSearch}
          className={classes.searchField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenContactModal}
          startIcon={<AddIcon />}
          className={classes.addButton}
        >
          Adicionar Fluxo
        </Button>
      </Box>

      <Card className={classes.tableCard}>
        <CardHeader
          className={classes.tableHeader}
          title={
            <Typography variant="h6" className={classes.tableHeaderTitle}>
              <DevicesFold /> Fluxos de Conversa
            </Typography>
          }
        />

        <CardContent style={{ padding: 0 }}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell}>Nome do Fluxo</TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">Status</TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={3} className={classes.loadingContainer}>
                      <CircularProgress size={40} />
                    </TableCell>
                  </TableRow>
                ) : displayedWebhooks.length > 0 ? (
                  displayedWebhooks.map((webhook) => (
                    <TableRow key={webhook.id} hover>
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => handleRowClick(webhook.id)}
                      >
                        <div className={classes.flowNameCell}>
                          <DevicesFold />
                          <span>{webhook.name}</span>
                        </div>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        align="center"
                        onClick={() => handleRowClick(webhook.id)}
                      >
                        <Chip
                          label={webhook.active ? "Ativo" : "Desativado"}
                          className={`${classes.statusChip} ${webhook.active ? classes.activeChip : classes.inactiveChip}`}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        <Button
                          aria-controls={open ? "flow-menu" : undefined}
                          aria-haspopup="true"
                          onClick={(e) => handleMenuOpen(e, webhook)}
                          variant="outlined"
                          color="primary"
                          size="small"
                          startIcon={<MoreVertIcon />}
                        >
                          Opções
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} className={classes.noDataContainer}>
                      <DevicesFold className={classes.noDataIcon} />
                      {searchParam ? (
                        <Typography variant="body1">
                          Nenhum fluxo encontrado para "{searchParam}".
                        </Typography>
                      ) : (
                        <Typography variant="body1">
                          Nenhum fluxo cadastrado. Clique em "Adicionar Fluxo" para criar um novo.
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {filteredWebhooks.length > 0 && (
            <Box className={classes.paginationContainer}>
              <Pagination
                count={Math.ceil(filteredWebhooks.length / itemsPerPage)}
                page={pageNumber}
                onChange={handlePageChange}
                color="primary"
                shape="rounded"
                showFirstButton
                showLastButton
                size="large"
              />
            </Box>
          )}
        </CardContent>
      </Card>

      <Menu
        id="flow-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 3,
          sx: {
            borderRadius: '8px',
            minWidth: '200px',
            padding: '8px 0'
          }
        }}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            if (menuWebhook) handleEditContact(menuWebhook);
          }}
          className={classes.menuItem}
        >
          <EditIcon fontSize="small" color="primary" />
          <span className={classes.menuItemText}>Editar nome</span>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleMenuClose();
            if (menuWebhook) handleEditFlow(menuWebhook);
          }}
          className={classes.menuItem}
        >
          <DevicesFold fontSize="small" color="primary" />
          <span className={classes.menuItemText}>Editar fluxo</span>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleMenuClose();
            if (menuWebhook) {
              setDeletingContact(menuWebhook);
              setConfirmDuplicateOpen(true);
            }
          }}
          className={classes.menuItem}
        >
          <FileCopyIcon fontSize="small" color="primary" />
          <span className={classes.menuItemText}>Duplicar</span>
        </MenuItem>

        <Divider className={classes.divider} />

        <MenuItem
          onClick={() => {
            handleMenuClose();
            if (menuWebhook) {
              setDeletingContact(menuWebhook);
              setConfirmOpen(true);
            }
          }}
          className={`${classes.menuItem} ${classes.menuItemDelete}`}
        >
          <DeleteIcon fontSize="small" color="error" />
          <span className={classes.menuItemText}>Excluir</span>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default FlowBuilder;