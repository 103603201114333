import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { i18n } from "../../translate/i18n";
import {
    Stack
} from "@mui/material";
import FlowBuilderTestRequest from "../FlowBuilderTestRequest";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },

    btnWrapper: {
        position: "relative"
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));

const selectFieldStyles = {
    ".MuiOutlinedInput-notchedOutline": {
        borderColor: "#909090"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000000",
        borderWidth: "thin"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#9a00ed",
        borderWidth: "thin"
    }
};

const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Muito curto!")
        .max(50, "Muito longo!")
        .required("Digite um nome!"),
    text: Yup.string()
        .min(2, "Muito curto!")
        .max(50, "Muito longo!")
        .required("Digite uma mensagem!")
});

const FlowBuilderUpdateContactModal = ({ open, onSave, onUpdate, data, close }) => {
    const classes = useStyles();
    const isMounted = useRef(true);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [cep, setCep] = useState("");
    const [address, setAddress] = useState("");
    const [bussinesName, setBussinesName] = useState("");
    const [activeModal, setActiveModal] = useState(false);
    const [modalHttpRequestOpen, setModalHttRequestOpen] = useState('');
    const [labels, setLabels] = useState({
        title: "Adicionar Atualização de contato ao fluxo",
        btn: "Adicionar"
    });

    useEffect(() => {
        if (open === "edit") {
            setLabels({
                title: "Editar Atualização de contatos",
                btn: "Salvar"
            });
            setName(data.data.name);
            setEmail(data.data.email);
            setCep(data.data.cep)
            setAddress(data.data.address);
            setBussinesName(data.data.bussinesName);
            setActiveModal(true);
        } else if (open === "create") {
            setLabels({
                title: "Atualizar Contato",
                btn: "Adicionar"
            });
            setEmail("");
            setName("");
            setCep("");
            setAddress("");
            setBussinesName("");
            setActiveModal(true);
        } else {
            setActiveModal(false);
        }
    }, [open]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleClose = () => {
        close(null);
        setActiveModal(false);
    };

    const handleSaveContact = async () => {
        if (open === "edit") {
            handleClose();
            onUpdate({
                ...data,
                data: { name: name, email: email, zipCode: cep, address: address, bussinesName: bussinesName }
            });
        } else if (open === "create") {
            handleClose();
            onSave({
                name: name, email: email, zipCode: cep, address: address, bussinesName: bussinesName
            });
        }
    };

    // const removeOption = number => {
    //     setArrayOption(old => old.filter(item => item.number !== number));
    // };
    // const removeOption2 = number => {
    //     setArrayOption2(old => old.filter(item => item.number !== number));
    // };

    // const updateOption = (index, field, value) => {
    //     setArrayOption(old => {
    //         let newArr = [...old];
    //         newArr[index][field] = value;
    //         return newArr;
    //     });
    // };

    // const updateOption2 = (index, field, value) => {
    //     setArrayOption2(old => {
    //         let newArr = [...old];
    //         newArr[index][field] = value;
    //         return newArr;
    //     });
    // };

    // const handleOpenModalHttpRequest = () => {
    //     setModalHttRequestOpen(true)
    // }

    return (
        <div className={classes.root}>
            <FlowBuilderTestRequest
                open={modalHttpRequestOpen}
                data={data}
                close={setModalHttRequestOpen}
            />
            <Dialog
                open={activeModal}
                onClose={handleClose}
                fullWidth="md"
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">{labels.title}</DialogTitle>
                <Stack>
                    <Stack direction={'row'} sx={{ gap: "8px", padding: "16px" }} >
                        <TextField
                            label={"Nome - (Váriavel)"}
                            minRows={1}
                            name="name"
                            multiline
                            variant="outlined"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            className={classes.textField}
                            style={{ width: "50%" }}
                        />
                        <TextField
                            label={"Email - (Váriavel)"}
                            minRows={1}
                            name="email"
                            multiline
                            variant="outlined"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className={classes.textField}
                            style={{ width: "50%" }}
                        />
                    </Stack>
                    <Stack direction={'row'} sx={{ gap: "8px", padding: "16px" }} >
                        <TextField
                            label={"Cep - (Váriavel)"}
                            minRows={1}
                            name="cep"
                            multiline
                            variant="outlined"
                            value={cep}
                            onChange={e => setCep(e.target.value)}
                            className={classes.textField}
                            style={{ width: "50%" }}
                        />
                        <TextField
                            label={"Endereço - (Váriavel)"}
                            minRows={1}
                            name="name"
                            multiline
                            variant="outlined"
                            value={address}
                            onChange={e => setAddress(e.target.value)}
                            className={classes.textField}
                            style={{ width: "50%" }}
                        />
                    </Stack>
                    <Stack direction={'row'} sx={{ gap: "8px", padding: "16px" }} >
                        <TextField
                            label={"Nome Empresa - (Váriavel)"}
                            minRows={1}
                            name="bussinesName"
                            multiline
                            variant="outlined"
                            value={cep}
                            onChange={e => setBussinesName(e.target.value)}
                            className={classes.textField}
                            style={{ width: "50%" }}
                        />
                    </Stack>

                    {/* <Typography style={{ marginLeft: '15px' }}>Informações Adicionais:</Typography>
                    <Stack dividers style={{ gap: "8px", padding: "16px" }}>
                        <TextField
                            label={"Body - (Conteudo em Json)"}
                            rows={4}
                            name="body"
                            multiline
                            variant="outlined"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className={classes.textField}
                            style={{ width: "100%" }}
                        />
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography>Headers</Typography>
                            <Button
                                onClick={() =>
                                    setArrayOption(old => [
                                        ...old,
                                        { number: old.length + 1, value: "" }
                                    ])
                                }
                                color="primary"
                                variant="contained"
                            >
                                <AddCircle />
                            </Button>
                        </Stack>
                        {arrayOption.map((item, index) => (
                            <Stack key={item.number} width="100%" alignItems="center">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <TextField
                                        placeholder={`Chave`}
                                        variant="outlined"
                                        value={item.key}
                                        style={{ width: "100%" }}
                                        onChange={e => updateOption(index, 'key', e.target.value)}
                                    />
                                    <TextField
                                        placeholder={`Conteúdo`}
                                        variant="outlined"
                                        value={item.value}
                                        style={{ width: "100%" }}
                                        onChange={e => updateOption(index, 'value', e.target.value)}
                                    />
                                    <IconButton onClick={() => removeOption(item.number)}>
                                        <Delete />
                                    </IconButton>
                                </div>
                            </Stack>
                        ))}
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography>Parâmetros</Typography>
                            <Button
                                onClick={() =>
                                    setArrayOption2(old => [
                                        ...old,
                                        { number: old.length + 1, value: "" }
                                    ])
                                }
                                color="primary"
                                variant="contained"
                            >
                                <AddCircle />
                            </Button>
                        </Stack>
                        {arrayOption2.map((item, index) => (
                            <Stack key={item.number} width="100%" alignItems="center">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <TextField
                                        placeholder={`Chave`}
                                        variant="outlined"
                                        value={item.key}
                                        style={{ width: "100%" }}
                                        onChange={e => updateOption2(index, 'key', e.target.value)}
                                    />
                                    <TextField
                                        placeholder={`Conteúdo`}
                                        variant="outlined"
                                        value={item.value}
                                        style={{ width: "100%" }}
                                        onChange={e => updateOption2(index, 'value', e.target.value)}
                                    />
                                    <IconButton onClick={() => removeOption2(item.number)}>
                                        <Delete />
                                    </IconButton>
                                </div>
                            </Stack>
                        ))}
                    </Stack> */}
                    <DialogActions>
                        {/* <Button onClick={handleOpenModalHttpRequest} color="secondary" variant="outlined">
                            {i18n.t("Testar")}
                        </Button> */}
                        <Button onClick={handleClose} color="secondary" variant="outlined">
                            {i18n.t("contactModal.buttons.cancel")}
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={classes.btnWrapper}
                            onClick={() => handleSaveContact()}
                        >
                            {`${labels.btn}`}
                        </Button>
                    </DialogActions>
                </Stack>
            </Dialog>
        </div>
    );
};

export default FlowBuilderUpdateContactModal;
