import React, { useState, useEffect, useRef, useContext } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Compressor from "compressorjs";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { Stack } from "@mui/material";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },

    btnWrapper: {
        position: "relative"
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));

const FlowBuilderAddDocumentModal = ({ open, onSave, onUpdate, data, close }) => {
    const classes = useStyles();
    const isMounted = useRef(true);

    const {user} = useContext(AuthContext);

    const [activeModal, setActiveModal] = useState(false);

    const [loading, setLoading] = useState(false);

    const [record, setRecord] = useState(false);

    const [preview, setPreview] = useState();

    const [labels, setLabels] = useState({
        title: "Adicionar video ao fluxo",
        btn: "Adicionar"
    });

    const [medias, setMedias] = useState([]);

    useEffect(() => {
        if (open === "edit") {
            setLabels({
                title: "Editar PDF",
                btn: "Salvar"
            });
            setPreview(process.env.REACT_APP_BACKEND_URL + `/public/company${user.companyId}` + data.data.url)
            setRecord(data.data.record)
            setActiveModal(true);
        } else if (open === "create") {
            setLabels({
                title: "Adicionar PDF ao fluxo",
                btn: "Adicionar"
            });
            setActiveModal(true);
        } else {
            setActiveModal(false);
        }
    }, [open]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleClose = () => {
        close(null);
        setActiveModal(false);
    };

    const handleSaveContact = async () => {
        if (open === "edit") {
            handleClose();
            onUpdate({
                ...data,
                data: {
                    url: data.data.url,
                    record: record
                }
            });
            return;
        } else if (open === "create") {
            setLoading(true);
            const formData = new FormData();
            formData.append("fromMe", true);

            medias.forEach(async (media, idx) => {
                const file = media;

                if (!file) {
                    return;
                }

                if (media?.type.split("/")[0] == "image") {
                    new Compressor(file, {
                        quality: 0.7,

                        async success(media) {
                            formData.append("medias", media);
                            formData.append("body", media.name);
                        },
                        error(err) {
                            alert("erro");
                        }
                    });
                } else {
                    formData.append("medias", media);
                    formData.append("body", media.name);
                }
            });

            setTimeout(async () => {
                await api.post("/flowbuilder/documents", formData).then(res => {
                    handleClose();
                    onSave({
                        url: res.data.name,
                    });
                    toast.success("PDF adicionada com sucesso!");
                    setLoading(false);
                    setMedias([]);
                    setPreview();
                });
            }, 1000);
        }
    };

    const handleChangeMedias = e => {
        if (!e.target.files) {
            return;
        }

        if (e.target.files[0].size > 20000000) {
            toast.error("Arquivo é muito grande! 20MB máximo")
            return
        }

        const selectedMedias = Array.from(e.target.files);
        setPreview((URL.createObjectURL(e.target.files[0])));
        setMedias(selectedMedias);
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={activeModal}
                onClose={handleClose}
                fullWidth="md"
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">{labels.title}</DialogTitle>
                <Stack>
                    <DialogContent dividers>
                        <Stack gap={"16px"}>
                            {preview && (
                                <Stack direction={'row'} justifyContent={'center'}>
                                    <embed
                                        src={`${preview}`}
                                        type="application/pdf"
                                        width="600px"
                                        height="500px"
                                    />
                                </Stack>
                            )}
                            {!loading && open !== "edit" && !preview && (
                                <>
                                    <Button variant="contained" component="label">
                                        Enviar PDF
                                        <input
                                            type="application/pdf"
                                            accept="pdf"
                                            disabled={loading}
                                            hidden
                                            onChange={handleChangeMedias}
                                        />
                                    </Button>
                                    <Typography>ATENÇÃO! Apenas arquivos PDF!</Typography>
                                </>
                            )}
                            {loading && (
                                <>
                                    <Stack justifyContent={"center"} alignSelf={"center"}>
                                        <CircularProgress />
                                    </Stack>
                                </>
                            )}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        {!loading && (
                            <>
                                <Button
                                    onClick={() => {
                                        handleClose();
                                        setMedias([]);
                                        setPreview();
                                    }}
                                    color="secondary"
                                    variant="outlined"
                                >
                                    {i18n.t("contactModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={loading}
                                    color="primary"
                                    variant="contained"
                                    className={classes.btnWrapper}
                                    onClick={() => handleSaveContact()}
                                >
                                    {`${labels.btn}`}
                                </Button>
                            </>
                        )}
                    </DialogActions>
                </Stack>
            </Dialog>
        </div>
    );
};

export default FlowBuilderAddDocumentModal;
