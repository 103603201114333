import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { i18n } from "../../translate/i18n";
import { Stack } from "@mui/material";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },

    btnWrapper: {
        position: "relative"
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));

const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Muito curto!")
        .max(50, "Muito longo!")
        .required("Digite um nome!"),
    text: Yup.string()
        .min(2, "Muito curto!")
        .max(50, "Muito longo!")
        .required("Digite uma mensagem!")
});

const FlowBuilderAddCloseTicket = ({ open, onSave, onUpdate, data, close }) => {
    const classes = useStyles();
    const isMounted = useRef(true);

    const [activeModal, setActiveModal] = useState(false);

    const [labels, setLabels] = useState({
        title: "Adicionar Mensagem de Encerramento",
        btn: "Adicionar"
    });

    const [textDig, setTextDig] = useState();
    const [textVariable, setTextVariable] = useState();

    useEffect(() => {
        if (open === "edit") {
            setLabels({
                title: "Editar Mensagem de Encerramento",
                btn: "Salvar"
            });
            setTextDig(data.data.label);
            setActiveModal(true);
        } else if (open === "create") {
            setLabels({
                title: "Adicionar Mensagem de Encerramento",
                btn: "Adicionar"
            });
            setTextDig("");
            setActiveModal(true);
        } else {
            setActiveModal(false);
        }
    }, [open]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleClose = () => {
        close(null)
        setActiveModal(false);
    };

    const handleSaveContact = async () => {
        if (open === "edit") {
            handleClose();
            onUpdate({
                ...data,
                data: { label: textDig }
            });
            return;
        } else if (open === "create") {
            handleClose();
            onSave({
                text: textDig
            });
        }
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={activeModal}
                onClose={handleClose}
                fullWidth="md"
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">{labels.title}</DialogTitle>
                <Stack>
                    <DialogContent dividers>
                        <>
                            <TextField
                                label={"Mensagem"}
                                multiline
                                rows={7}
                                name="text"
                                variant="outlined"
                                value={textDig}
                                margin="dense"
                                onChange={e => setTextDig(e.target.value)}
                                className={classes.textField}
                                style={{ width: "95%" }}
                            />
                        </>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary" variant="outlined">
                            {i18n.t("contactModal.buttons.cancel")}
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={classes.btnWrapper}
                            onClick={() => handleSaveContact()}
                        >
                            {`${labels.btn}`}
                        </Button>
                    </DialogActions>
                </Stack>
            </Dialog>
        </div>
    );
};

export default FlowBuilderAddCloseTicket;
