import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  Container,
  CircularProgress,
  Alert
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import api from '../../services/api';

const SatisfactionDashboard = () => {


  const [satisfactionData, setSatisfactionData] = useState({
    totalResponses: 0,
    averageScore: 0,
    breakdown: [],
    agents: []
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: '2023-12-03',
    endDate: '2025-12-20'
  });

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const formattedStartDate = dateRange.startDate.split('-').reverse().join('/');
      const formattedEndDate = dateRange.endDate.split('-').reverse().join('/');

      const response = await api.get(`/satisfaction/`, {
        params: {
          startDate: formattedStartDate,
          endDate: formattedEndDate
        }
      });

      const formattedData = {
        totalResponses: response.data.totalResponses,
        averageScore: response.data.averageScore,
        breakdown: [
          { label: 'Muito Insatisfeito', count: response.data.veryDissatisfied || 0, emoji: '😠' },
          { label: 'Insatisfeito', count: response.data.dissatisfied || 0, emoji: '🙁' },
          { label: 'Indiferente', count: response.data.neutral || 0, emoji: '😐' },
          { label: 'Satisfeito', count: response.data.satisfied || 0, emoji: '😊' },
          { label: 'Muito Satisfeito', count: response.data.verySatisfied || 0, emoji: '😍' },
        ],
        agents: response.data.agents || []
      };

      setSatisfactionData(formattedData);
    } catch (err) {
      setError('Erro ao carregar os dados. Por favor, tente novamente.');
      console.error('Erro ao buscar dados:', err);
    } finally {
      setLoading(false);
    }
  }, [dateRange.startDate, dateRange.endDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDateChange = (type) => (event) => {
    const newDate = event.target.value;
    setDateRange((prev) => ({
      ...prev,
      [type]: newDate,
    }));
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  };

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ minHeight: '100vh', bgcolor: 'background.default', p: 3 }}>
        <Container maxWidth="xl">

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h4" component="h1">
              Pesquisa de Satisfação
            </Typography>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography>De Início</Typography>
                <TextField
                  type="date"
                  value={dateRange.startDate}
                  onChange={handleDateChange('startDate')}
                  size="small"
                  sx={{ width: '180px' }}
                />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography>De Fim</Typography>
                <TextField
                  type="date"
                  value={dateRange.endDate}
                  onChange={handleDateChange('endDate')}
                  size="small"
                  sx={{ width: '180px' }}
                />
              </Box>
            </Box>
          </Box>


          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                    <Box sx={{ width: 16, height: 16, bgcolor: 'success.main' }} />
                    <Box sx={{ width: 16, height: 16, bgcolor: 'warning.main' }} />
                    <Box sx={{ width: 16, height: 16, bgcolor: 'error.main' }} />
                  </Box>
                  <Box>
                    <Typography variant="h4">{satisfactionData.totalResponses}</Typography>
                    <Typography variant="body2" color="text.secondary">Respostas</Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box sx={{
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    bgcolor: 'primary.main',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    📊
                  </Box>
                  <Box>
                    <Typography variant="h4">{satisfactionData.averageScore}</Typography>
                    <Typography variant="body2" color="text.secondary">Média</Typography>
                  </Box>
                </Box>
              </Box>

              <Grid container spacing={2}>
                {satisfactionData.breakdown.map((item, index) => (
                  <Grid item xs={12} sm={2.4} key={index}>
                    <Paper sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      bgcolor: 'background.paper'
                    }}>
                      <Typography variant="h4">{item.emoji}</Typography>
                      <Typography variant="h5">{item.count}</Typography>
                      <Typography variant="body2" color="text.secondary" align="center">
                        {item.label}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>


          <Card>
            <CardContent>
              <Typography variant="h5" sx={{ mb: 2 }}>
                Atendentes
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Insatisfeito</TableCell>
                    <TableCell>Indiferentes</TableCell>
                    <TableCell>Satisfeito</TableCell>
                    <TableCell>Nota Média</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {satisfactionData.agents.map((agent, index) => (
                    <TableRow key={index}>
                      <TableCell>{agent.name}</TableCell>
                      <TableCell>{agent.insatisfeito}</TableCell>
                      <TableCell>{agent.indiferentes}</TableCell>
                      <TableCell>{agent.satisfeito}</TableCell>
                      <TableCell>{agent.notaMedia.toFixed(1)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Container>
      </Box >
    </LocalizationProvider >

  );
};

export default SatisfactionDashboard;