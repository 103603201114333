import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import {
    IconButton,
    makeStyles,
    Avatar,
    Paper,
    Typography,
    Popper,
    Fade,
    Badge
} from '@material-ui/core';
import ChatWindow from '../../components/chat/ChatWindow';
import api from "../../services/api"
import { AuthContext } from '../Auth/AuthContext';
import { socketManager } from '../Socket/SocketContext';
import usePlans from "../../hooks/usePlans";
import { useLicenseChecker } from '../../license/validateLicense';

const ChatContext = createContext();

const useStyles = makeStyles((theme) => ({
    userList: {
        position: 'fixed',
        right: theme.spacing(3),
        bottom: theme.spacing(22),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        zIndex: 1200,
        maxHeight: `calc(100vh - ${theme.spacing(30)}px)`,
        overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1),
        boxShadow: theme.shadows[3],
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.grey[400],
            borderRadius: '3px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: theme.palette.grey[500],
        },
    },
    userListContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    userItem: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5),
        borderRadius: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    chatButton: {
        position: 'fixed',
        right: theme.spacing(3),
        bottom: theme.spacing(10),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        zIndex: 1200,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    badge: {
        position: 'fixed',
        right: theme.spacing(3),
        bottom: theme.spacing(10),
        zIndex: 1201,
        '& .MuiBadge-badge': {
            backgroundColor: '#ff4444',
            color: 'white',
            fontWeight: 'bold',
            minWidth: '20px',
            height: '20px',
            borderRadius: '10px',
            padding: '0 6px',
            fontSize: '0.75rem',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    userBubble: {
        position: 'relative',
        cursor: 'pointer',
        backgroundColor: theme.palette.background.paper,
    },
    onlineIndicator: {
        position: 'absolute',
        bottom: 2,
        right: 2,
        width: 8,
        height: 8,
        backgroundColor: '#44b700',
        borderRadius: '50%',
        border: '2px solid #fff',
    },
    namePopper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
        boxShadow: theme.shadows[2],
        zIndex: 1400,
    },
    chatBadge: {
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#fff',
            fontSize: '0.8rem',
            minWidth: '20px',
            height: '20px',
            padding: '0 10px',
        },
    },
    newMessagesCountBadge: {
        height: "20px",
        width: "20px",
        background: "#00BEF5",
        borderRadius: "65%",
        fontFamily: "Montserrat",
        fontSize: "10px",
        fontWeight: "400",
        lineHeight: "14px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        position: "absolute",
        right: "10px",
        top: "4px",
    },
}));

export const ChatContextProvider = ({ children }) => {
    const [isListVisible, setIsListVisible] = useState(false);
    const [chatsPersonals, setChatsPersonals] = useState([]);
    const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);
    const [activeChat, setActiveChat] = useState(null);
    const [chatActual, setChatActual] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const { user } = useContext(AuthContext);
    const processedMessages = useRef(new Set());
    const { plano } = useLicenseChecker();
    const { getPlanCompany } = usePlans();
    const [showInternalChat, setShowInternalChat] = useState(false);

    // Add a ref to track socket connection
    const socketInitialized = useRef(false);
    const classes = useStyles();

    useEffect(() => {
        findChats().then((data) => {
            const { records } = data;
            if (records.length > 0) {
                const chatsRecordsPersonals = records.filter(
                    (c) => c.isGroup === false
                );
                setChatsPersonals(chatsRecordsPersonals);

                const total = chatsRecordsPersonals.reduce((acc, chat) => {
                    const currentUser = chat.users.find(u => u.userId === user.id);
                    return acc + (currentUser?.unreads || 0);
                }, 0);
                setTotalUnreadMessages(total);
            }
        });
    }, [user]);



    useEffect(() => {
        async function fetchData() {
            const planConfigs = await getPlanCompany(undefined, user.companyId);
            setShowInternalChat(planConfigs.plan.useInternalChat);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        const socket = socketManager.getSocket(user.companyId);

        const handleNewMessage = (data) => {
            // console.log(data)
            if (data.action === "new-message") {
                const { chat, newMessage } = data;
                // Create a unique identifier for this message
                const messageId = `${newMessage.id}`;
                // Check if we've already processed this message
                if (processedMessages.current.has(messageId)) {
                    console.log('Duplicate message detected:', messageId);
                    return;
                }
                // console.log('Processing new message:', messageId);

                // Add message to processed set
                processedMessages.current.add(messageId);

                // Clear old messages from the Set after 5 seconds
                setTimeout(() => {
                    processedMessages.current.delete(messageId);
                }, 5000);

                const userInChat = chat.users.some(u => u.userId === user.id);
                if (!userInChat) {
                    console.log('User not in chat, ignoring message');
                    return;
                }

                if (newMessage.senderId !== user.id) {
                    setChatsPersonals(currentChats => {
                        const updatedChats = updateChatsWithNewMessage(currentChats, data);
                        setTimeout(() => {
                            updateUnreadCount(updatedChats);
                        }, 0);
                        return updatedChats;
                    });
                }
            }

            if (data.action === "update") {
                const { chat } = data;
                const updateId = `update-${chat.id}-${Date.now()}`;

                if (processedMessages.current.has(updateId)) {
                    console.log('Duplicate update detected:', updateId);
                    return;
                }

                processedMessages.current.add(updateId);

                setTimeout(() => {
                    processedMessages.current.delete(updateId);
                }, 5000);

                const userInChat = chat.users.some(u => u.userId === user.id);
                if (!userInChat) return;

                setChatsPersonals(currentChats => {
                    const updatedChats = updateChatReadMessages(currentChats, data);
                    setTimeout(() => updateUnreadCount(updatedChats), 0);
                    return updatedChats;
                });
            }
        };

        socket.on(`company-${user.companyId}-chat`, handleNewMessage);
        socketInitialized.current = true;

        return () => {
            console.log('Cleaning up socket connection...');
            // socket.off(`company-${user.companyId}-chat`, handleNewMessage);
            socketInitialized.current = false;
            processedMessages.current.clear();
        };
    }, [user]);


    const findChats = async () => {
        try {
            const { data } = await api.get("/chats");
            return data;
        } catch (err) {
            console.log(err);
        }
    };

    const updateChatsWithNewMessage = (currentChats, newMessageData) => {
        const { chat, newMessage } = newMessageData;

        return currentChats.map(existingChat => {
            if (existingChat.id === chat.id) {
                // Find and update the current user's unread count
                const updatedUsers = existingChat.users.map(chatUser => {
                    if (chatUser.userId === user.id && newMessage.senderId !== user.id) {
                        return {
                            ...chatUser,
                            unreads: (chatUser.unreads || 0) + 1
                        };
                    }
                    return chatUser;
                });

                return {
                    ...existingChat,
                    users: updatedUsers,
                    lastMessage: newMessage
                };
            }
            return existingChat;
        });
    };

    const updateChatReadMessages = (currentChats, chatData) => {
        const { chat } = chatData;

        return currentChats.map(existingChat => {
            if (existingChat.id === chat.id) {
                const updatedUsers = existingChat.users.map(chatUser => {
                    if (chatUser.userId === user.id) {
                        // Atualiza o usuário atual com as mensagens lidas (unreads = 0)
                        return {
                            ...chatUser,
                            unreads: 0
                        };
                    }
                    return chatUser;
                });

                return {
                    ...existingChat,
                    users: updatedUsers
                };
            }
            return existingChat;
        });
    };

    const updateUnreadCount = (chats) => {
        const total = chats.reduce((acc, chat) => {
            const currentUser = chat.users.find(u => u.userId === user.id);
            return acc + (currentUser?.unreads || 0);
        }, 0);
        setTotalUnreadMessages(total);
    };

    const getInitials = (name) => {
        if (!name) return '';
        return name
            .split(' ')
            .map(word => word[0])
            .join('')
            .toUpperCase()
            .slice(0, 2);
    };

    const handleRenderAvatar = (chat) => {
        const otherUser = chat.users.find(i => i.userId !== user?.id)?.user;
        const profilePicture = otherUser?.profilePicture;
        const name = otherUser?.name || '';

        if (profilePicture) {
            return <Avatar src={profilePicture} className={classes.avatar} />;
        }

        return <Avatar className={classes.avatar}>{getInitials(name)}</Avatar>;
    };


    const getPrimaryText = (chat) => {
        const mainText =
            user.name === chat.title ? chat.titlePersonals : chat.title;
        const unreads = []
        return (
            <>
                <div>
                    <div
                        style={{ marginLeft: "0" }}
                        className={classes.ticketContactName}
                    >
                        {mainText}
                    </div>
                    {unreads > 0 && (
                        <Badge className={classes.newMessagesCountBadge}>{unreads}</Badge>
                    )}
                </div>
            </>
        );
    };

    const unreadMessages = (chat) => {
        const currentUser = chat.users.find((u) => u.userId === user.id);
        return currentUser.unreads;
    };

    const toggleUserList = () => {
        setIsListVisible(!isListVisible);
        if (!isListVisible) {
            setActiveChat(null);
        }
    };

    const handleMouseEnter = (event, userId) => {
        setAnchorEl({ element: event.currentTarget, id: userId });
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);
    };

    const handleChatClick = (chat) => {

        const getUserChat = user.name === chat.title ? chat.titlePersonals : chat.title;

        const findUserIdChat = chat.users.filter((i) => i.user.name === getUserChat);

        if (findUserIdChat.length > 0) {

            setActiveChat(findUserIdChat[0].user.id);
            setChatActual(findUserIdChat[0].chatId)
            setIsListVisible(false);
        } else {

        }
        // setActiveChat(userId);
        // setIsListVisible(false);
    };

    const contextValue = {
        isListVisible,
        activeChat,
        toggleUserList,
        handleChatClick,
        setActiveChat
    };

    return (
        <ChatContext.Provider value={contextValue}>
            {children}

            {(plano === "basico" || plano === "empresarial" || plano === "ultimate") && showInternalChat && (
                <Badge
                    className={classes.badge}
                    badgeContent={totalUnreadMessages}
                    color="error"
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <IconButton
                        className={classes.chatButton}
                        onClick={toggleUserList}
                        aria-label="open chat"
                    >
                        <ChatBubbleOutlineIcon />
                    </IconButton>
                </Badge>
            )}


            {isListVisible && (
                <Paper className={classes.userList}>
                    <div className={classes.userListContainer}>
                        {Array.isArray(chatsPersonals) &&
                            chatsPersonals.length > 0 && chatsPersonals.map((chat, key) => (
                                <div key={chat.id} className={classes.userItem}>
                                    <IconButton
                                        className={classes.userBubble}
                                        onMouseEnter={(e) => handleMouseEnter(e, chat.id)}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={() => handleChatClick(chat)}
                                    >
                                        {handleRenderAvatar(chat)}
                                        {<span className={classes.onlineIndicator} />}
                                        {unreadMessages(chat) > 0 && (
                                            <span className={classes.newMessagesCountBadge}>
                                                {unreadMessages(chat)}
                                            </span>
                                        )}
                                    </IconButton>

                                    <Popper
                                        open={Boolean(anchorEl) && anchorEl.id === chat.id}
                                        anchorEl={anchorEl?.element}
                                        placement="left"
                                        transition
                                        style={{ zIndex: 1400 }}
                                        modifiers={{
                                            preventOverflow: {
                                                enabled: true,
                                                boundariesElement: 'viewport',
                                            },
                                            flip: {
                                                enabled: true,
                                            },
                                        }}
                                    >
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={200}>
                                                <Paper className={classes.namePopper}>
                                                    <Typography variant="body2">{getPrimaryText(chat)}</Typography>
                                                </Paper>
                                            </Fade>
                                        )}
                                    </Popper>
                                </div>
                            ))}
                    </div>
                </Paper>
            )}

            {activeChat && (
                <>
                    <ChatWindow
                        user={chatsPersonals
                            .find(chat => chat.users.some(user => user.userId === activeChat))
                            .users.find(user => user.userId === activeChat).user}
                        chatId={chatActual}
                        chats={chatsPersonals}
                        userId={user.id}
                        chatActual={chatActual}
                        onClose={() => setActiveChat(null)}
                    />
                </>
            )}
        </ChatContext.Provider>
    );
};
