import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { i18n } from "../../translate/i18n";
import { Select, Stack, MenuItem } from "@mui/material";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1
    },
    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    btnWrapper: {
        position: "relative"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));

const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Muito curto!")
        .max(50, "Muito longo!")
        .required("Digite um nome!"),
    text: Yup.string()
        .min(2, "Muito curto!")
        .max(50, "Muito longo!")
        .required("Digite uma mensagem!")
});

const FlowBuilderAddCollectModal = ({ open, onSave, onUpdate, data, variable, close }) => {
    const classes = useStyles();
    const isMounted = useRef(true);

    const [variaveis, setVariaveis] = useState([]);
    const [activeModal, setActiveModal] = useState(false);
    const [labels, setLabels] = useState({
        title: "Coletar Váriavel",
        btn: "Adicionar"
    });

    const [textVariable, setTextVariable] = useState("");

    useEffect(() => {
        const filterVariables = variable.filter(v => v.type === "question").map(m => m.data.variable);
        setVariaveis(filterVariables);
    }, [variable]);

    useEffect(() => {
        if (open === "edit") {
            setLabels({
                title: "Editar Coleta de variavel",
                btn: "Salvar"
            });
            setTextVariable(data.data.collect);
            setActiveModal(true);
        } else if (open === "create") {
            setLabels({
                title: "Coletar Váriavel",
                btn: "Adicionar"
            });
            setTextVariable("");
            setActiveModal(true);
        } else {
            setActiveModal(false);
        }
    }, [open, data]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleClose = () => {
        close(null);
        setActiveModal(false);
    };

    const handleSaveContact = async () => {
        if (open === "edit") {
            handleClose();
            onUpdate({
                ...data,
                data: {collect: textVariable }
            });
            return;
        } else if (open === "create") {
            handleClose();
            onSave({
                collect: textVariable
            });
        }
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={activeModal}
                onClose={handleClose}
                fullWidth="md"
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">{labels.title}</DialogTitle>
                <Stack>
                    <DialogContent dividers>
                        <>
                            <Select
                                name="collect"
                                label="Variável"
                                value={textVariable}
                                onChange={(e) => setTextVariable(e.target.value)}
                                style={{ width: '100%' }}
                            >
                                {variaveis.map((variable, index) => (
                                    <MenuItem key={index} value={variable}>
                                        {variable}
                                    </MenuItem>
                                ))}
                            </Select>
                        </>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary" variant="outlined">
                            {i18n.t("contactModal.buttons.cancel")}
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={classes.btnWrapper}
                            onClick={handleSaveContact}
                        >
                            {labels.btn}
                        </Button>
                    </DialogActions>
                </Stack>
            </Dialog>
        </div>
    );
};

export default FlowBuilderAddCollectModal;
