import React, { useState, useEffect, useRef } from 'react';
import {
    Paper,
    IconButton,
    Typography,
    makeStyles,
    Avatar,
    FormControl,
    CircularProgress,
    InputAdornment,
    Input
} from '@material-ui/core';
import { Send as SendIcon, Close as CloseIcon } from '@material-ui/icons';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CancelIcon from "@material-ui/icons/Cancel";
import MicIcon from "@material-ui/icons/Mic";
import MicRecorder from "mic-recorder-to-mp3";
import ChatMessage from '../ChatMessage';
import api from '../../../services/api';
import RecordingTimer from '../../MessageInputCustom/RecordingTimer';
import toastError from '../../../errors/toastError';
import { green } from '@material-ui/core/colors';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { socketManager } from '../../../context/Socket/SocketContext';

const useStyles = makeStyles((theme) => ({
    chatWindow: {
        position: 'fixed',
        right: theme.spacing(16),
        bottom: theme.spacing(3),
        width: 320,
        height: '60vh',
        borderRadius: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },
    chatHeader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(1, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexShrink: 0,
    },
    chatBody: {
        padding: theme.spacing(2),
        flexShrink: 0,
    },
    messageList: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'auto',
        padding: theme.spacing(1, 2),
        maxHeight: 'calc(100% - 180px)',
    },
    inputContainer: {
        display: 'flex',
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        flexShrink: 0,
    },

    mainContainer: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        flex: 1,
        overflow: "hidden",

        borderRadius: 0,
        height: "100%",
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    },
    messageList: {
        position: "relative",
        overflowY: "auto",
        overflowX: "hidden",
        height: "100%",
        ...theme.scrollbarStyles,
        backgroundColor: theme.palette.background.default,
    },
    inputArea: {
        position: "relative",
        height: "auto",
    },
    input: {
        padding: "20px",
        width: '100%',
        overflowX: 'hidden'
    },
    buttonSend: {
        margin: theme.spacing(1),
        cursor: 'pointer'
    },
    boxLeft: {
        padding: "10px 10px 5px",
        margin: "10px",
        position: "relative",
        backgroundColor: "white",
        maxWidth: 300,
        borderRadius: 10,
        borderBottomLeftRadius: 0,
        border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    boxRight: {
        padding: "10px 10px 5px",
        margin: "10px 10px 10px auto",
        position: "relative",
        backgroundColor: "#E3F3F9",
        textAlign: "right",
        maxWidth: 300,
        borderRadius: 10,
        borderBottomRightRadius: 0,
        border: "1px solid rgba(0, 0, 0, 0.12)",
    },

    sendMessageIcons: {
        color: "grey",
    },
    uploadInput: {
        display: "none"
    },
    circleLoading: {
        color: green[500],
        opacity: "70%",
        position: "absolute",
        top: "20%",
        left: "50%",
        marginLeft: -12,
    },
    viewMediaInputWrapper: {
        display: "flex",
        padding: "10px 13px",
        position: "relative",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#eee",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },

    downloadMedia: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "inherit",
        padding: 10,
    },
    messageMedia: {
        objectFit: "cover",
        width: 250,
        height: 200,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
    },

    recorderWrapper: {
        display: "flex",
        alignItems: "center",
        alignContent: "middle",
        justifyContent: 'flex-end',
    },

    cancelAudioIcon: {
        color: "red",
    },


    audioLoading: {
        color: green[500],
        opacity: "70%",
    },

    sendAudioIcon: {
        color: "green",
    },
}));

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const ChatWindow = ({ user, onClose, chatId, chats, userId, chatActual }) => {
    const classes = useStyles();
    const [messages, setMessages] = useState([]);
    const [medias, setMedias] = useState([]);
    const [recording, setRecording] = useState(false);
    const [contentMessage, setContentMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [messagesPage, setMessagesPage] = useState(1);
    const [messagesPageInfo, setMessagesPageInfo] = useState({ hasMore: false });
    const messageListRef = useRef(null);
    const [isDraggingFile, setIsDraggingFile] = useState(false);

    useEffect(() => {
        findMessages(chatId);
        goToMessages(chatId)
    }, [user.id, chatId]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        const companyId = user.companyId;

        const socket = socketManager.getSocket(user.companyId);
        const onCurrentChat = (data) => {
            if (data.action === "new-message") {
                if (chatId === chatActual) {

                    setMessages((prev) => [...prev, data.newMessage]);

                    if (chats) {
                        const changedChats = chats.map((chat) => {
                            if (chat.id === data.newMessage.chatId) {
                                return { ...data.chat };
                            }
                            return chat;
                        });
                    }
                }
            }
        };


        socket.on(`company-${companyId}-chat-${chatId}`, onCurrentChat);


        return () => {

            socket.on(`company-${companyId}-chat-${chatId}`, onCurrentChat);

        };
    }, [user, chatId]);

    const goToMessages = async (chatId) => {

        try {
            await api.post(`/chats/${chatId}/read`, { userId: user.id });
        } catch (err) { }
    };

    const scrollToBottom = () => {
        if (messageListRef.current) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    };

    const findMessages = async (chatId) => {
        if (!chatId) return;

        setLoading(true);
        try {
            const { data } = await api.get(`/chats/${chatId}/messages?pageNumber=${messagesPage}`);

            setMessagesPage((prev) => prev + 1);
            setMessagesPageInfo(data);
            setMessages((prev) => [...data.records, ...prev]);
        } catch (err) {
            console.error("Erro ao buscar mensagens:", err);
        } finally {
            setLoading(false);
        }
    };

    const handleSendMessage = async () => {
        if (!contentMessage.trim()) return;

        setLoading(true);
        try {
            await api.post(`/chats/${chatId}/messages`, {
                message: contentMessage,
            });
            setContentMessage('');
        } catch (err) {
            console.error("Erro ao enviar mensagem:", err);
        } finally {
            setLoading(false);
        }
    };

    const handleSendMedia = async (e) => {
        setLoading(true);
        e.preventDefault();

        const formData = new FormData();
        formData.append("fromMe", true);
        medias.forEach((media) => {
            formData.append("medias", media);
            formData.append("body", media.name);
        });

        try {
            await api.post(`/chats/${chatId}/messages`, formData);
        } catch (err) {
            console.log(err);
            toastError(err);
        }

        setLoading(false);
        setMedias([]);
        setIsDraggingFile(false)
    };

    const handleStartRecording = async () => {
        setLoading(true);
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            await Mp3Recorder.start();
            setRecording(true);
            setLoading(false);
        } catch (err) {
            toastError(err);
            setLoading(false);
        }
    };

    const handleUploadAudio = async () => {
        setLoading(true);
        try {
            const [, blob] = await Mp3Recorder.stop().getMp3();

            if (blob.size < 10000) {
                setLoading(false);
                setRecording(false);
                return;
            }

            const formData = new FormData();
            const filename = `audio-${new Date().getTime()}.mp3`;

            formData.append("medias", blob, filename);
            formData.append("body", filename);
            formData.append("fromMe", true);

            await api.post(`/chats/${chatId}/messages`, formData);
        } catch (err) {
            toastError(err);
        }

        setRecording(false);
        setLoading(false);
    };

    const handleCancelAudio = async () => {
        try {
            await Mp3Recorder.stop().getMp3();
            setRecording(false);
        } catch (err) {
            toastError(err);
        }
    };

    const handleChangeMedias = (e) => {


        if (!e.target.files) {
            return;
        }

        const selectedMedias = Array.from(e.target.files);
        setMedias(selectedMedias);
    };

    const handleInputPaste = (e) => {
        if (e.clipboardData.files[0]) {
            setMedias([e.clipboardData.files[0]]);
        }
    };

    const handleDrop = (acceptedFiles) => {
        console.log("Dropped files:", acceptedFiles);
        const validFiles = acceptedFiles.filter(file => file.size <= 15728640);
        console.log("Valid files:", validFiles);

        if (validFiles.length !== acceptedFiles.length) {
            toast.error('Alguns arquivos excedem o limite de 15 MB e foram removidos.');
        }

        if (validFiles.length > 0) {
            handleChangeMedias({
                target: {
                    files: validFiles,
                },
            });
        }
    };

    const disableOption = () => {
        return loading || recording
    };


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        multiple: true,
        disabled: disableOption(),
        onDrop: handleDrop,
        onClick: (event) => {
            event.stopPropagation();
        },
        onDragEnter: (event) => {
            if (event.dataTransfer?.types.includes('Files')) {
                setIsDraggingFile(true);
            }
        },
        onDragLeave: () => {
            setIsDraggingFile(false);
        },
        onDropRejected: () => {
            setIsDraggingFile(false);
        }
    });


    return (
        <Paper className={classes.chatWindow}>
            <div className={classes.chatHeader}>
                <Typography variant="subtitle1">{user.name}</Typography>
                <IconButton size="small" onClick={onClose} color="inherit">
                    <CloseIcon />
                </IconButton>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margintop: '30px'
                }}
            >
                <Avatar src={user.profilePicture} />

            </div>
            <div className={classes.chatBody}>
                <Typography variant="body2" color="textSecondary" align="center">
                    Início da conversa
                </Typography>
            </div>

            <div className={classes.messageList} ref={messageListRef}>
                {loading && messages.length === 0 ? (
                    <Typography align="center">Carregando...</Typography>
                ) : (
                    messages.map((msg, key) => (
                        <ChatMessage
                            key={msg.id || key}
                            message={msg}
                            isOwn={msg.sender.id === userId ? true : false}
                        />
                    ))
                )}
            </div>

            <div className={classes.inputArea}>
                <FormControl variant="outlined" fullWidth>

                    {recording ? (
                        <div className={classes.recorderWrapper}>
                            <IconButton
                                aria-label="cancelRecording"
                                component="span"
                                fontSize="large"
                                disabled={loading}
                                onClick={handleCancelAudio}
                            >
                                <HighlightOffIcon className={classes.cancelAudioIcon} />
                            </IconButton>
                            {loading ? (
                                <div>
                                    <CircularProgress className={classes.audioLoading} />
                                </div>
                            ) : (
                                <RecordingTimer />
                            )}

                            <IconButton
                                aria-label="sendRecordedAudio"
                                component="span"
                                onClick={handleUploadAudio}
                                disabled={loading}
                            >
                                <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
                            </IconButton>
                        </div>

                    )
                        :
                        <>
                            {medias.length > 0 ?
                                <>
                                    <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
                                        <IconButton
                                            aria-label="cancel-upload"
                                            component="span"
                                            onClick={(e) => setMedias([])}
                                        >
                                            <CancelIcon className={classes.sendMessageIcons} />
                                        </IconButton>

                                        {loading ? (
                                            <div>
                                                <CircularProgress className={classes.circleLoading} />
                                            </div>
                                        ) : (
                                            <span>
                                                {medias[0]?.name}
                                            </span>
                                        )}
                                        <IconButton
                                            aria-label="send-upload"
                                            component="span"
                                            onClick={handleSendMedia}
                                            disabled={loading}
                                        >
                                            <SendIcon className={classes.sendMessageIcons} />
                                        </IconButton>
                                    </Paper>
                                </>
                                :
                                <React.Fragment>

                                    <div {...getRootProps()} style={{ position: 'relative', width: '100%' }}>
                                        {isDraggingFile && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    backgroundColor: 'rgba(0,0,0,0.1)',
                                                    zIndex: 1000,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    border: '2px dashed #007bff',
                                                    borderRadius: '4px',
                                                    pointerEvents: 'none' // Importante para evitar interferência
                                                }}
                                            >
                                                Solte o arquivo aqui
                                            </div>
                                        )}
                                        <Input
                                            multiline
                                            value={contentMessage}
                                            onPaste={handleInputPaste}
                                            onKeyUp={(e) => {
                                                if (e.key === "Enter" && contentMessage.trim() !== "") {
                                                    handleSendMessage(contentMessage);
                                                    setContentMessage("");
                                                }
                                            }}
                                            onChange={(e) => setContentMessage(e.target.value)}
                                            className={classes.input}
                                            startAdornment={
                                                <InputAdornment position="start">

                                                    <FileInput disableOption={disableOption} handleChangeMedias={handleChangeMedias} />

                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {contentMessage ? (
                                                        <IconButton
                                                            onClick={() => {
                                                                if (contentMessage.trim() !== "") {
                                                                    handleSendMessage(contentMessage);
                                                                    setContentMessage("");
                                                                }
                                                            }}
                                                            className={classes.buttonSend}
                                                        >
                                                            <SendIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton
                                                            aria-label="showRecorder"
                                                            component="span"
                                                            disabled={loading}
                                                            onClick={handleStartRecording}
                                                        >
                                                            <MicIcon className={classes.sendMessageIcons} />
                                                        </IconButton>
                                                    )}
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                </React.Fragment>
                            }
                        </>
                    }

                </FormControl>
            </div>
        </Paper>
    );
};


const FileInput = (props) => {
    const { handleChangeMedias, disableOption } = props;
    const classes = useStyles();
    return (
        <>
            <input
                multiple
                type="file"
                id="upload-button"
                disabled={disableOption}
                className={classes.uploadInput}
                onChange={handleChangeMedias}
                style={{
                    position: 'start',
                    cursor: 'pointer'
                }}
            />
            <label htmlFor="upload-button" style={{
                position: 'start',
                cursor: 'pointer'
            }}>
                <IconButton
                    aria-label="upload"
                    component="span"
                    disabled={disableOption}
                    style={{
                        position: 'start',
                        cursor: 'pointer'
                    }}
                >
                    <AttachFileIcon className={classes.sendMessageIcons} style={{ position: 'start', cursor: 'pointer' }} />
                </IconButton>
            </label>
        </>
    );
};

export default ChatWindow;